import { z } from "zod";

import {
  Condition,
  jsonParseOrReturn,
  parseDateString,
  validateConditionValue,
} from "@smart/itops-utils-basic";

import { FieldType } from "./field";
import {
  PhoneNumberResponse,
  isOfPhoneNumberResponseType,
} from "./phone-number-response";

export const linkConditionValue = [
  "equalTo",
  "notEqualTo",
  "greaterThan",
  "greaterThanOrEqualTo",
  "lessThan",
  "lessThanOrEqualTo",
  "in",
  "contain",
] as const;

export const linkConditionLabel: Record<
  (typeof linkConditionValue)[number],
  string
> = {
  equalTo: "equal to",
  notEqualTo: "not equal to",
  greaterThan: "greater than",
  greaterThanOrEqualTo: "greater than or equal to",
  lessThan: "less than",
  lessThanOrEqualTo: "less than or equal to",
  in: "includes any of",
  contain: "includes all of",
};

export const linkConditionSchema = z.enum(linkConditionValue);

export const fieldLinkSchema = z.object({
  fieldUri: z.string(),
  condition: linkConditionSchema,
  value: z.string(),
  hide: z.boolean().optional().nullable(),
});

export type LinkConditionType = (typeof linkConditionValue)[number];

export type FieldLinkType = z.infer<typeof fieldLinkSchema>;

export const validateConditionLink = ({
  conditionLink,
  fieldValue,
  fieldType,
  group,
}: {
  conditionLink: FieldLinkType;
  fieldValue: string | number | string[] | PhoneNumberResponse;
  fieldType: FieldType;
  group:
    | {
        uri: string | undefined | null;
        repeatable?: boolean | null;
      }
    | undefined;
}): boolean => {
  // If the field belongs to a repeatable group, ignore the condition
  if (group?.repeatable) return true;

  const getConvertedValue = () => {
    // If the field belongs to a group, it is an array of values, so pick the first one.
    const selectedValue =
      group?.uri && Array.isArray(fieldValue) ? fieldValue[0] : fieldValue;

    if (fieldType === "date")
      return parseDateString(selectedValue as string).getTime();
    if (isOfPhoneNumberResponseType(selectedValue))
      return selectedValue.formattedNumber;
    return selectedValue;
  };

  const getConditionValue = () => {
    const value = jsonParseOrReturn(conditionLink.value);
    if (fieldType === "date") return parseDateString(value).getTime();
    return value;
  };

  const condition: Condition = {
    value: getConditionValue(),
    condition: conditionLink.condition,
  };

  // check if condition is met
  const isConditionMet = validateConditionValue(getConvertedValue(), condition);

  // if conditionLink.hide is true, it's a 'hiding condition': hide the question if the condition is met (invert the result)

  // if conditionLink.hide is false, it's a 'showing condition': show the question if the condition is met (return the result as is)

  return conditionLink.hide ? !isConditionMet : isConditionMet;
};
