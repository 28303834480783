import styled from "@emotion/styled";

import { isTestEnv } from "@smart/itops-utils-basic";

export const sidebarWidth = 300;
export const sidebarSpeed = "0.4s";

export const ScrollWrapper = styled.div<{ newUI?: boolean }>`
  position: ${(props) => (props.newUI ? "relative" : "absolute")};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  container-type: size;
  overflow: hidden;
`;

export const PageWrapper = styled.div<{ sidebar: boolean }>`
  width: 100%;
  height: 100%;
  transition: padding-left ${sidebarSpeed} ease;
  overflow: hidden auto;

  scroll-behavior: smooth;
  scroll-padding-top: 15rem;

  ${(props) =>
    isTestEnv()
      ? ""
      : `
  @container (min-width: ${props.theme.breakPoints.readable + sidebarWidth}px) {
    padding-left: ${props.sidebar ? sidebarWidth * props.theme.baseUnit : 0}px;
  }
  `}
`;

export const Page = styled.main`
  --background: ${(props) => props.theme.scheme.grey.r0};
  background: var(--background);
  border-radius: 4px;
  margin: ${(props) => props.theme.baseUnit * 3}rem auto
    ${(props) => props.theme.baseUnit * 10}rem;
  max-width: 90%;
  width: ${(props) => props.theme.breakPoints.readable}px;
`;

export const Section = styled.section`
  padding: 1rem 2rem;

  a {
    color: ${(props) => props.theme.palette.primary.base};
    text-decoration: underline;
  }
`;
