import { ForwardedRef, forwardRef, ReactNode } from "react";
import { CurrencyInputProps } from "react-currency-input-field";

import { Icon, IconDetail } from "@smart/itops-icons-dom";

import { Currency, InputKind, InputWrapper } from "./wrapper";
import { FontSizeKey, SchemeColor } from "../../theme";

export type CurrencyProps = {
  size?: FontSizeKey;
  kind?: InputKind;
  icon?: IconDetail & { schemeColor?: SchemeColor };
  right?: ReactNode;
  error?: boolean;
  className?: string;
} & Pick<
  CurrencyInputProps,
  | "id"
  | "disabled"
  | "name"
  | "title"
  | "type"
  | "placeholder"
  | "value"
  | "defaultValue"
  | "onChange"
  | "onBlur"
  | "onFocus"
  | "onKeyDown"
  | "readOnly"
  | "intlConfig"
  | "onValueChange"
  | "prefix"
>;

const BaseCurrencyInput = (
  {
    id,
    size,
    kind,
    icon,
    right,
    error,
    disabled,
    className,
    defaultValue,
    ...currencyProps
  }: CurrencyProps,
  ref: ForwardedRef<HTMLInputElement>,
) => (
  <InputWrapper
    kind={kind}
    size={size}
    className={className}
    aria-disabled={disabled}
    iconColor={icon?.schemeColor}
    defaultValue={defaultValue}
  >
    {icon && <Icon className="input-icon" size={18} {...icon} />}
    <Currency
      id={id}
      disabled={disabled}
      aria-invalid={error}
      aria-errormessage={error ? `${id}-error` : undefined}
      ref={ref}
      defaultValue={defaultValue}
      decimalScale={2}
      {...currencyProps}
    />
    {right}
  </InputWrapper>
);
export const CurrencyInput = forwardRef(BaseCurrencyInput);
