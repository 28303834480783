import { loadLocale } from "@smart/itops-locale-dom";
import { CurrencyInput, FieldGroup } from "@smart/itops-ui-dom";

import { Label } from "./label";
import { FieldProps, FieldWrapper } from "./wrapper";
import { fieldName } from "../../hooks";

export const CurrencyField = ({
  field,
  index,
  innerRef,
  value,
  error,
  loading,
  disabled,
  onChange,
  onBlur,
}: FieldProps<HTMLInputElement>) => {
  const locale = loadLocale();
  const id = fieldName({ field, index });
  const errorId = fieldName({ field, index, suffix: "error" });

  return (
    <FieldWrapper aria-disabled={disabled} isLoading={loading}>
      <FieldGroup
        id={id}
        error={error}
        hint={field.hint ?? undefined}
        label={<Label {...field} index={index} />}
      >
        <CurrencyInput
          prefix={locale.currencySymbol}
          intlConfig={locale}
          ref={innerRef}
          id={id}
          name={id}
          aria-invalid={!!error}
          aria-errormessage={errorId ?? undefined}
          value={value?.value ?? ""}
          onValueChange={(_, name, values) => {
            onChange(values && values.float ? values : null);
          }}
          onBlur={onBlur}
          size="base"
        />
      </FieldGroup>
    </FieldWrapper>
  );
};
