import JSZip from "jszip";

const contentTypes = {
  png: "image/png",
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  pdf: "application/pdf",
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
};

export const downloadAndUpload = async ({
  downloadUrl,
  uploadUrl,
  fileName,
  fileExtension,
}: {
  downloadUrl: string;
  uploadUrl: string;
  fileName: string;
  fileExtension?: string;
}) => {
  const downloadedFile = await fetch(downloadUrl);

  if (downloadedFile.status !== 200)
    throw new Error(`Failed to download file from url ${downloadUrl}`);

  const blob = await downloadedFile.blob();

  const fileToUpload = new global.File([blob], fileName, {
    type: fileExtension
      ? contentTypes[fileExtension as keyof typeof contentTypes]
      : undefined,
  });
  const uploadResult = await fetch(uploadUrl, {
    method: "PUT",
    body: fileToUpload,
    headers: {
      "Content-Type": fileToUpload.type,
    },
  });

  if (uploadResult.status !== 200)
    throw new Error(`Failed to upload file ${fileName}`);
};

export const checkIsPdfEncrypted = async (fileToCheck: File | Buffer) => {
  const checkIsContentEncrypted = (content: string | undefined) =>
    content
      ?.substring(content.lastIndexOf("<<"), content.lastIndexOf(">>"))
      .includes("/Encrypt");

  const type = "application/pdf";
  if (fileToCheck instanceof File) {
    const reader = new FileReader();

    return new Promise<boolean>((res, rej) => {
      reader.onload = async () => {
        const blob = reader.result
          ? new Blob([reader.result], { type })
          : undefined;
        const isEncrypted = checkIsContentEncrypted(await blob?.text());

        res(!!isEncrypted);
      };
      reader.onerror = rej;
      reader.readAsArrayBuffer(fileToCheck);
    });
  }

  const blob = new Blob([fileToCheck], { type });
  return checkIsContentEncrypted(await blob.text());
};

export const checkIsWordDocEncrypted = async (fileToCheck: File | Buffer) => {
  const zip = new JSZip();
  try {
    await zip.loadAsync(fileToCheck);
    return false;
  } catch (error) {
    return true;
  }
};
