import { validateConditionLink } from "@smart/bridge-types-basic";
import { fromEntries, keysOf } from "@smart/itops-utils-basic";

import { FieldItem, GroupItem, LinkItem, SectionItem } from "../types";

export type VisibilityOptions = {
  sections: SectionItem[];
  groups: GroupItem[];
  fields: FieldItem[];
  responses: Record<string, any>;
};

export type Visibility = {
  visibleFields: Record<string, boolean>;
  visibleGroups: Record<string, boolean>;
  visibleSections: Record<string, boolean>;
  willBeHidden: (props: Record<string, any>) => string[];
};

const visibilityCheck = ({
  fields,
  links,
  responses,
  groups,
}: {
  fields: FieldItem[];
  links: LinkItem[] | undefined | null;
  responses: Record<string, any>;
  groups: GroupItem[];
}) =>
  !links?.length ||
  links.every((link) => {
    const field = fields.find((f) => f.uri === link.fieldUri);
    return (
      !field ||
      validateConditionLink({
        conditionLink: link,
        fieldValue: responses[link.fieldUri],
        fieldType: field.type,
        group: groups?.find((g) => field.groupUri && g.uri === field.groupUri),
      })
    );
  });

const createVisibilityMap = ({
  sections,
  groups,
  fields,
  responses,
}: VisibilityOptions) => {
  const visibleSections = fromEntries(
    sections.map(({ uri, links }) => [
      uri,
      visibilityCheck({ fields, responses, links, groups }),
    ]),
  );
  const visibleGroups = fromEntries(
    groups.map(({ uri, sectionUri, links }) => [
      uri,
      visibleSections[sectionUri]
        ? visibilityCheck({ fields, responses, links, groups })
        : false,
    ]),
  );
  const visibleFields = fromEntries(
    fields.map(({ uri, sectionUri, groupUri, links }) => [
      uri,
      visibleSections[sectionUri] && (!groupUri || visibleGroups[groupUri])
        ? visibilityCheck({ fields, responses, links, groups })
        : false,
    ]),
  );

  return { visibleSections, visibleGroups, visibleFields };
};

const checkForHidden = (
  options: VisibilityOptions,
  existing: Record<string, boolean>,
  responses: Record<string, any>,
) => {
  const updated = createVisibilityMap({
    ...options,
    responses,
  });

  return {
    updatedFields: updated.visibleFields,
    updatedUris: keysOf(updated.visibleFields).filter(
      (u) => !updated.visibleFields[u] && !!existing[u],
    ),
  };
};

export const useVisibility = (options: VisibilityOptions): Visibility => {
  const { visibleSections, visibleGroups, visibleFields } =
    createVisibilityMap(options);

  return {
    visibleSections,
    visibleGroups,
    visibleFields,
    willBeHidden: (update) => {
      const uris: string[] = [];
      const responses = { ...options.responses, ...update };

      let shouldCheck = true;
      let existing = visibleFields;
      let iteration = 0;
      while (shouldCheck && iteration <= 5) {
        const { updatedFields, updatedUris } = checkForHidden(
          options,
          existing,
          responses,
        );
        existing = updatedFields;
        shouldCheck = !!updatedUris.length;
        for (const uri of updatedUris) {
          responses[uri] = null;
          uris.push(uri);
        }

        iteration += 1;
      }

      return uris;
    },
  };
};
